import { Injectable, WritableSignal, signal } from '@angular/core';
import { isTouchDevice } from '../utils';

// allowedKeyCodes = return, 0-9,                               =    /?
const ALLOWED_BARCODE_KEYCODES = [13, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
// ignoredKeyCodes =     /?   ;  Shift Caps
const IGNORED_BARCODE_KEYCODES = [187, 191, 186, 16, 20];

declare type ReaderServiceCallback = (data: KeyboardEvent) => boolean;

declare interface ReaderServiceCallbackTypeMap {
    isBarcode: boolean, callback: ReaderServiceCallback;
}

export const keycodes = {
    backspace: 8,
    tab: 9,
    enter: 13,
    shift: 16,
    ctrl: 17,
    alt: 18,
    pause_break: 19,
    caps_lock: 20,
    escape: 27,
    space: 32,
    page_up: 33,
    page_down: 34,
    end: 35,
    home: 36,
    left_arrow: 37,
    up_arrow: 38,
    right_arrow: 39,
    down_arrow: 40,
    insert: 45,
    delete: 46,
    f1: 112,
    f2: 113,
    f3: 114,
    f4: 115,
    f5: 116,
    f6: 117,
    f7: 118,
    f8: 119,
    f9: 120,
    f10: 121,
    f11: 122,
    f12: 123,
    num_lock: 144,
    scroll_lock: 145
};


@Injectable({ providedIn: 'root' })
export class BarcodeScannerService {
    isTouch: boolean = isTouchDevice();
    disabled: WritableSignal<boolean> = signal(false);

    constructor() { }
    callbacks: ReaderServiceCallbackTypeMap[] = [];

    listen = (handler: any, isBarcode = false) => {
        const buffer: any = [];
        const threshold: any = this.isTouch ? 120 : 60; // ms
        let timer: any = null;

        function notify() {
            const input = buffer.join('').trim();

            clearTimeout(timer);
            buffer.length = 0;
            handler(input);
        }

        const callback = (event: KeyboardEvent) => {
            if (timer) clearTimeout(timer);

            if (this.disabled() === false) {
                timer = setTimeout(notify, threshold);
            }

            if (event.charCode == keycodes.enter) {
                event.stopImmediatePropagation();

                return false;
            }

            if (isBarcode) {
                if (IGNORED_BARCODE_KEYCODES.indexOf(event.keyCode) >= 0) {
                    return;
                }

                if (ALLOWED_BARCODE_KEYCODES.indexOf(event.keyCode) >= 0) {
                    buffer.push(String.fromCharCode(event.keyCode));
                } else {
                    buffer.length = 0;
                }
            } else {
                buffer.push(String.fromCharCode(event.charCode));
            }

        };

        if (this.callbacks.length) {
            const lastCallback = this.callbacks[this.callbacks.length - 1].callback;
            document.body.removeEventListener('keypress', lastCallback);

        }

        this.callbacks.push({ callback, isBarcode });

        document.body.addEventListener('keypress', callback);

    };

    destroy() {
        const lastCallbackItem = this.callbacks.pop();

        document.body.removeEventListener('keypress', lastCallbackItem.callback);

        if (this.callbacks.length) {
            const lastCallback = this.callbacks[this.callbacks.length - 1];
            console.log(' next callback', lastCallback);
            document.body.addEventListener('keypress', lastCallback.callback);

        }
    }

}
