import { EntityInterface } from '../services/entity.service';
import { PromanFile, Template } from '../interfaces/entity-interfaces';

export const template = {
    name: 'template',
    params: {
        entityConfig: {
            name: 'template',
            post: [
                'copy',
                'developmentProject',
                'orderProposal',
                'xyzReport',
                'production',
                'productionProductComponent',
                'shipmentContainer',
                'personalCalendar',
                'productionList',
                'inventorization',
                'articleTest',
                'shipmentList',
                'orderList',
                'purchaseList',
                'render',
                'articleReport',
                'salarySlip',
                'salarySlips',
                'informCustomers',
                'informSuppliers',
                'selectedSalarySlips',
                'customerDocument',
                'supplierDocument',
                'assetDocuments',
                'defectAct',
                'stringReplace',
                'movementOfGoods',
                'operationList',
                'employeeOperationList',
                'customerClaim',
                'employeesDocuments',
                'sendNewsletter',
                'invoiceReport',
                'selectedProductions',
                'orderProductReport',
                'healthAppointment',
                'orderProposal',
            ]
        },
        contexts: [
            'bill_of_lading',
            'development',
            'order_confirmation',
            'certificate',
            'additional',
            'invoice',
            'production',
            'order_proposal',
            'employee_contract',
            'employee_vacations',
            'purchase',
            'purchase_payment',
            'production_list',
            'order_list',
            'operation_list',
            'shipment_list',
            'bill_of_containers',
            'technology_description',
            'pos_daily_report_action',
            'consumer_booking_confirm',
            'xyz_report',
            'employee_document',
            'accounting_report',
            'test_report',
            'articles_report',
            'customer_document',
            'customer_delay',
            'shipping_confirmation',
            'defect_act',
            'movement_of_goods',
            'salary_payments',
            'contact_form',
            'customer_password_reset_shop',
            'customer_email_confirmation_shop',
            'customer_registration_information_shop',
            'customer_order_confirmation_shop',
            'customer_order_changed_shop',
            'customer_order_executed_shop',
            'customer_order_sent_shop',
            'customer_order_order_shop',
            'customer_claim',
            'dynamic_table_template',
            'asset',
            'supplier_document',
            'advertisement',
            'product_in_stock',
            'instructions',
            'invoice_product',
            'invoice_email',
            'trips',
            'articles_sales_report',
            'selected_productions',
            'employee_operations',
            'smarttag',
            'ledger_report',
            'inventorization'
        ],
        types: [
            'html',
            'xml',
            'zpl',
            'zpl2',
            'email',
            'text',
            'sms',
            'form',
            'pdf',
            'csv',
        ],
        get: {
            join: [
                'namespaces',
            ]
        },
        contextTypes: [
            'tax',
            'employee',
            'inventory',
            'declaration',
        ],
        functions: {
            invoice: {
                order: 'order',
                positions: 'invoice_records',
                products: 'invoice_records',
                totalQuantity: 'total_quantity',
                sumInOrderCurrency: 'sum_in_order_currency',
                sumInOrderCurrencyWithVat: 'sum_in_order_currency_with_vat',
                sumInOrderCurrencySpelled: 'sum_in_order_currency_spelled',
                sumInMainCurrency: 'sum_in_main_currency',
                sumInMainCurrencyWithVat: 'sum_in_main_currency_with_vat',
                sumInMainCurrencySpelled: 'sum_in_main_currency_spelled',
                mainCurrency: 'default_currency',
                orderCurrency: 'order_currency',
                vat: 'vat',
                vatSumInOrderCurrency: 'vat_sum',
                vatSumInMainCurrency: 'vat_sum_in_default_currency',
                payer: 'payer',
                receiver: 'receiver',
                trackingNumber: 'tracking_number',
                date: 'date',
                invoiceAddress: 'invoice_address',
                deliveryAddress: 'delivery_address',
                shippingPaidByCustomer: 'logistics_paid_by_customer',
                shippingFee: 'shipping_free',
                carrier: 'carrier',
                deliveryDate: 'delivery_date',
            },
            production: {

            },
            production_list: {
                order: 'order',
                productions: 'productions'
            },
            shipment_list: {
                shipments: 'shipments'
            },
            order_list: {
                orders: 'orders'
            },
            order_confirmation: {

            },
            certificate: {

            },
            order_proposal: {
                orderProposal: 'order_proposal',
                orderProposalField: 'dynamic_fields',
                article: 'article',
                articleField: 'dynamic_fields'
            },
        }

    }
};

export interface TemplateEntityInterface extends EntityInterface<Template> {
    render: (data: { id: number }) => Promise<string>;
    xyzReport: (data: { template: number }) => Promise<string>;
    shipmentList: (data: { template: number; shipments: number|number[]; type?: string, width?: number, height?: number, length?: number, weight?: number, package?: number }) => Promise<string>;
    orderList: (data: { template: number; orders: number|number[] }) => Promise<string>;
    productionList: (data: { template: number; order: number }) => Promise<string>;
    inventorization: (data: { template: number; inventorization: number }) => Promise<string>;
    purchaseList: (data: { template: number; purchases: number|number[] }) => Promise<string>;
    selectedProductions: (data: { template: number; productions: number|number[] }) => Promise<any>;
    articleTest: (data: { template: number; test: number }) => Promise<string>;
    customerClaim: (data: { template: number; claim: number }) => Promise<string>;
    assetDocuments: (data: { template: number; id: number }) => Promise<any>;
    operationList: (data: { ids: number[]; template: number }) => Promise<any>;
    employeeOperationList: (data: { ids: number[]; template: number; employee: number}) => Promise<any>;
    informCustomers: (data: { template: number; id: number[] }) => Promise<any>;
    informSuppliers: (data: { template: number; id: number[] }) => Promise<any>;
    articleReport: (data: { template: number; id: number[] }) => Promise<PromanFile>;
    orderProductReport: (data: { template: number; from: string, to: string, type: string, dataType: string, category: any }) => Promise<string>;
    employeesDocuments: (data: { template: number; id: number[] }) => Promise<any>;
    salarySlip: (data: { employee: number;  template: number; date: string; addVacationCompensation: boolean; addEmployeePayout: boolean }) => Promise<string>;
    salarySlips: (data: { template: number; date: string }) => Promise<string>;
    selectedSalarySlips: (data: { template: number; date: string; employees: number[]}) => Promise<string>;
    customerDocument: (data: { template: number; customer: number; date: string }) => Promise<PromanFile>;
    supplierDocument: (data: { template: number; supplier: number; date: string }) => Promise<string>;
    defectAct: (data: { template: number; resourceBooking: number; comment: string }) => Promise<string>;
    stringReplace: (data: { templates: number[]; search: string; replace: string }) => Promise<string>;
    production: (data: { template: number }) => Promise<string>;
    developmentProject: (data: { template: number; developmentProject: number }) => Promise<string>;
    sendNewsletter: (data: { subject: string, template: number }) => Promise<unknown>;
    invoiceReport: (data: { template: number, id: number[] }) => Promise<unknown>;
    healthAppointment: (data: { template: number, id: number[] }) => Promise<unknown>;
    orderProposal: (data: { template: number; orderProposal: number }) => Promise<PromanFile>;
}
