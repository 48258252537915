import { EntityInterface } from '../services/entity.service';

export const shipment_container = {
    name: 'shipment_container',
    params: {
        entityConfig: {
            name: 'shipment_container',
            get: [ 'getBarcodeZpl' ],
            post: [ 'add', 'updateShipmentProductWeight']
        }
    }
};

export interface ShipmentContainerEntityInterface extends EntityInterface {
    updateShipmentProductWeight: (data: { id: number }) => Promise<void>;
}
