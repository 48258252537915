import { EntityInterface } from '../services/entity.service';

export const gonpay = {
    name: 'gonpay',
    params: {
        entityConfig: {
            name: 'gonpay',
            post: [
                'buyer_info',
                'transaction_begin',
                'transaction_end',
                'transaction_prepaid_spend',
                'transaction_credit_spend',
            ]
        }
    }
};

export interface GonpayEntityInterface extends EntityInterface {
    buyer_info: (data: { bimId: string }) => Promise<any>;
    transaction_begin: (data: { bimId: string, orderId: number, loyaltyDeductAmount?: number }) => Promise<any>;
    transaction_end: (data: { transactionId: string, receiptNumber: number, ttl: number }) => Promise<any>;
    transaction_prepaid_spend: (data: { transactionId: number, amount: number }) => Promise<any>;
    transaction_credit_spend: (data: { transactionId: number, amount: number }) => Promise<any>;
}
