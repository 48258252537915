import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { ArticleCategory } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-category-recursive',
    template: `
<!--        <div>{{ category.name }} {{ isPreviewVisible }}</div>-->
        <div fxLayout="column"
             *ngIf="(category.isActiveParent || category.isEnabled)
              || isEditMode
              || isPreview && (previewParent || isPreviewVisible)">
            <div fxLayout="column">
                    @if (isPreview) {
                        <div (click)="handlePreviewCategoryClick($event)" class="Clickable">
                            <fa [name]="'folder-tree'"></fa>
                            @if (category.children?.length) {
                                <fa [name]="isPreviewVisible ? 'minus' : 'plus'"></fa>
                            }
                            <span>{{category.name}} </span>
                            <a [routerLink]="itemEntityState | pmSref: category.id">
                                <fa [name]="'chevron-double-right'"></fa>
                            </a>
                        </div>

                    } @else {
                        <pro-checkbox fxFlex
                                      [config]="{ label: category.name }"
                                      [value]="category.isEnabled"
                                      (onChange)="onToggle.emit({ type: 'categories', field: category, value: $event })"
                                      [disabled]="!isEditMode"></pro-checkbox>
                    }

            </div>
            @if ((!isPreview || isPreviewVisible) && category.children?.length) {
                <div fxLayout="column"
                     class="CategoryRecursive--Children">
                    <pm-category-recursive *ngFor="let _category of category.children"
                                           [category]="_category"
                                           [isEditMode]="isEditMode"
                                           [isPreview]="isPreview"
                                           [previewCats]="previewCats"
                                           [previewParent]="isPreviewVisible"
                                           [items]="items"
                                           [itemEntityState]="itemEntityState"
                                           [categoryState]="categoryState"
                                           (onToggle)="onToggle.emit($event);"
                                           (onTogglePreviewChild)="onTogglePreviewChild.emit($event);"></pm-category-recursive>
                </div>
            }

            @if (isPreview && isPreviewVisible && validItems.length) {
                <div class="CategoryRecursive--Children">
                    @for (item of validItems; track item.id) {
                        <div>
                            <fa [name]="'file'"></fa>
                            <span (click)="onTogglePreviewChild.emit(item)">{{item.name}} </span>
                            <a [routerLink]="itemEntityState | pmSref: item.id" class="Clickable">
                                <fa [name]="'chevron-double-right'"></fa>
                            </a>
                        </div>
                    }
                </div>
            }
        </div>

    `,
    styles: `
        .CategoryRecursive--Children {
            margin: 8px 0;
            padding: 0 16px;
            border-left: 2px dashed #BFBFBF;
        }
        :host {
            //outline: 1px solid green;
        }
        fa {
            font-size: 10px;
            margin-right: 8px;
            margin-left: 4px;
        }
    `
})

export class CategoryRecursiveComponent implements OnInit {
    @Input() category: any;
    @Input() isEditMode: boolean;
    @Input() isPreview: boolean;
    @Input() previewCats: number[];
    @Input() previewParent: boolean;
    @Input() items: any[];
    @Input() itemEntityState: string;
    @Input() categoryState: string;
    @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();
    @Output() onTogglePreviewChild: EventEmitter<ArticleCategory> = new EventEmitter<ArticleCategory>();
    validItems: any[] = [];
    isPreviewVisible: boolean;

    constructor() {

    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.validItems = (this.items || [])
            .filter((item) => item?.categories.some((c: { id: number }) => c.id === this.category.id));

        this.isPreviewVisible = this.previewVisible();
    }

    previewVisible() {
        return this.previewCats ? this.previewCats.includes(this.category.id) : false;
    }

    handlePreviewCategoryClick(event: MouseEvent) {
        this.onTogglePreviewChild.emit(this.category);
    }

}
