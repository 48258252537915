import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type TooltipPosition = 'top'|'bottom'|'left'|'right';

@Directive({
    selector: '[proTooltip]',
    standalone: true,
})
export class TooltipDirective implements OnInit, OnDestroy, OnChanges {
    @Input('proTooltip') tooltip: string;
    @Input('proTooltipPosition') position: TooltipPosition = 'top';

    constructor(
        private element: ElementRef,
        private Render2: Renderer2,
        private Translate: TranslateService,
    ) {

    }

    ngOnInit() {
        this.setTooltip();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setTooltip();
    }

    ngOnDestroy() {

    }

    setTooltip() {
        if (this.tooltip) {
            this.Render2.setAttribute(this.element.nativeElement, 'data-tooltip', this.Translate.instant(this.tooltip));

        }

        if (this.position) {
            this.Render2.addClass(this.element.nativeElement, `has-tooltip-${this.position}`);

        }

    }

}
