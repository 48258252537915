import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { prepareRequest } from '../../utils';
import { EntityItemFieldConfig } from '../../interfaces/object-interfaces';
import { CommonModule } from '@angular/common';
import { DialogTitleComponent } from '../../common-components/standalone/dialog-title.component';
import { DialogActionsComponent } from '../../common-components/standalone/dialog-actions.component';
import { PromanButtonComponent } from '../../button';
import { PromanParametersModule } from '../../parameters/proman-parameters.module';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
    selector: 'pro-input-dialog',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatLegacyDialogModule,
        PromanParametersModule,

        DialogTitleComponent,
        DialogActionsComponent,
        PromanButtonComponent,
    ],
    template: `
        <form #createForm="ngForm"
              [formGroup]="form"
              (ngSubmit)="create(createForm)">
            <pro-dialog-title [title]="data.header || ''"></pro-dialog-title>
            <div mat-dialog-content fxLayout="column">
                @for (parameter of parameters; track $index) {
                    <pro-parameter [parameter]="parameter"
                                   [config]="parameter.config"
                                   [disabled]="parameter.disabled"
                                   (onChange)="set(parameter.key, $event)"></pro-parameter>
                }
            </div>
            <pro-dialog-actions [variant]="data.variant || 'confirm'" [isCallbackDisabled]="!form.valid">
                @if (data.buttons) {
                    @for (button of data.buttons; track $index) {
                        <pro-btn [label]="button.label"
                                 [theme]="button.theme"
                                 (onClick)="getCallback(button)">

                        </pro-btn>
                    }
                }
            </pro-dialog-actions>
        </form>
    `
})

export class InputDialogComponent {
    parameters: EntityItemFieldConfig[];
    form: UntypedFormGroup;
    controls: any = {};
    inputData: any = {};

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { [key: string]: any },
        public dialogRef: MatLegacyDialogRef<InputDialogComponent>
    ) {
        const mainField: EntityItemFieldConfig = {
            key: 'name',
            name: 'name',
            type: 'string',
            config: {
                required: true,
                control: new UntypedFormControl('', [Validators.required]),
            }
        };

        this.parameters = [
            Object.assign({}, mainField, this.data.mainField || {})
        ];

        if (this.data.parameters) {
            this.parameters = [].concat(this.parameters, this.data.parameters);

        }

        for (const parameter of this.parameters) {

            if (this.data.item) parameter.value = this.data.item[parameter.key];

            if (parameter.config.control) {
                this.controls[parameter.key] = parameter.config.control;

            }

            if (parameter.config.required && !this.controls[parameter.key]) {
                const control = new UntypedFormControl(parameter.value || '', Validators.required);

                this.controls[parameter.key] = control;
                parameter.config.control = control;

            }

            if (parameter.value) {
                this.set(parameter.key, parameter.value);
                if (parameter.config.control) {
                    parameter.config.control.value = parameter.value;
                }
            }

            if (parameter.type === 'string') {
                parameter.config = Object.assign({ debounce: 0 }, parameter.config);

            }

        }

        this.form = new UntypedFormGroup(this.controls);
    }

    set(property: string, value: any) {
        this.inputData[property] = value;
    }

    create(createForm: any) {

        if (createForm.valid) {
            const data = prepareRequest(this.inputData);
            this.parameters.filter((param) => param.type === 'list-manager').forEach((param) => {
                Object.assign(data, param.config.mainEntity[param.config.key]);
            })
            this.dialogRef.close(data);
        }

    }

    getCallback(button: any) {

        if (this.form.valid) {
            const data = prepareRequest(this.inputData);
            this.dialogRef.close();
            button.callback(data);
        }

    }

}
