import { EntityInterface } from '../services/entity.service';
import { OrderProduct } from '../interfaces/entity-interfaces';

export const order_product = {
    name: 'order_product',
    params: {
        entityConfig: {
            name: 'order_product',
            post: ['updateArticle', 'posRemove', 'posUpdate', 'positionQuantity', 'purchaseCreate']
        },
        extraParameters() {
            return {
                join: [
                    'product.warehouseLocation',
                    'product.warehouseLocation.warehouse',
                ],
                partialJoin: {
                    // 'product': ['id', 'name', 'lotNumber', 'storeName'],
                    'product.article': ['id', 'name', 'altName'],
                    'product.article.type': ['name'],
                    'order.customer': ['name', 'alias'],
                    'order.manager': ['name'],
                    'order.type': ['name'],
                    'order': ['id', 'name', 'number', 'customerNumber', 'createdAt', 'status', 'desiredDispatchDate']
                },
                sort: { 'id': 'desc' }
            };
        }
    }
};

export interface OrderProductEntityInterface extends EntityInterface<OrderProduct> {
    posRemove: (data: { id: number; device?: number, quantity?: number, return_materials?: boolean, reason?: string }) => Promise<void>;
    purchaseCreate: (data: {id: number[]; }) => Promise<unknown>
}
