import { product } from './product';
import { order } from './order';
import { order_product } from './order_product';
import { production_operation } from './production_operation';
import { production } from './production';
import { article } from './article';
import { amortization } from './amortization';
import { article_operation_production_comment } from './article-operation-production-comment';
import { calendar } from './calendar';
import { printer } from './printer';
import { customer } from './customer';
import { development_project } from './development-project';
import { dynamic_table } from './dynamic-table';
import { event } from './event';
import { event_link } from './event-link';
import { employee } from './employee';
import { employee_document } from './employee-document';
import { invoice } from './invoice';
import { listener_subscriber } from './listener_subscriber';
import { material } from './material';
import { maintenance } from './maintenance';
import { material_quant } from './material_quant';
import { purchase } from './purchase';
import { operation } from './operation';
import { parameter } from './parameter';
import { product_container } from './product_container';
import { shipment } from './shipment';
import { workgroup } from './workgroup';
import { workplace } from './workplace';
import { workplace_employee } from './workplace-employee';
import { account_category } from './account_category';
import { notification } from './notification';
import { expression } from './expression';
import { exception_log } from './exception_log';
import { material_type } from './material_type';
import { template } from './template';
import { production_product } from './production_product';
import { sale_opportunity } from './sale_opportunity';
import { audit_log } from './audit_log';
import { widget } from './widget';
import { currency } from './currency';
import { order_proposal_parameter } from './order_proposal_parameter';
import { order_template } from './order_template';
import { order_proposal_product } from './order_proposal_product';
import { order_proposal } from './order_proposal';
import { shipment_container } from './shipment_container';
import { file } from './file';
import { feature } from './feature';
import { document } from './document';
import { camera } from './camera';
import { article_material } from './article_material';
import { material_category } from './material_category';
import { production_parameter } from './production_parameter';
import { user } from './user';
import { agent } from './agent';
import { cash_register } from './cash_register';
import { consumer_booking } from './consumer_booking';
import { sale_event } from './sale_event';
import { loyalty_card } from './loyalty_card';
import { element } from './element';
import { system_options } from './system_options';
import { pos_account } from './pos_account';
import { shipment_product } from './shipment_product';
import { supplier } from './supplier';
import { report_sales } from './report_sales';
import { report_materials } from './report_materials';
import { account_registry } from './account_registry';
import { company_account } from './company_account';
import { assets } from './assets';
import { ocr } from './ocr';
import { employee_contract } from './employee_contract';
import { calendar_integrations_controller } from './calendar_integrations_controller';
import { confirmation } from './confirmation';
import { device } from './device';
import { article_test } from './article_test';
import { eshop } from './eshop';
import { article_operation } from './article_operation';
import { loose_relation } from './loose_relation';
import { stripe } from './stripe';
import { entrance_card } from './entrance_card';
import { shop } from './shop';
import { payment_provider } from './payment_provider';
import { shopcart } from './shop-cart';
import { v2auth } from './v2-auth';
import { workshift } from './workshift';
import { entrance } from './entrance';
import { corporate_namespace } from './corporate_namespace';
import { paypal } from './paypal';
import { online_payments } from './online_payments';
import { create_online_meeting } from './create_online_meeting';
import { user_menu } from './user_menu';
import { login_block } from './login_block';
import { payment_coupon } from './payment_coupon';
import { smart_tag_product } from './smart_tag_product';
import { container } from './container';
import { permission } from './permission';
import { server } from './server';
import { translation } from './translation';
import { public_container_status } from './public_container_status';
import { measurement_result } from './measurement_result';
import { fiscal } from './fiscal';
import { tax } from './tax';
import { ledger } from './ledger';
import { general_ledger_record } from './general_ledger_record';
import { body_pressure_point } from './body_pressure_point';
import { tag } from './tag';
import { quant_event } from './quant_event';
import { discount_tag } from './discount_tag';
import { payment } from './payment';
import { inventorization_by_date } from './inventorization-by-date';
import { account_category_year_balance } from './account_category_year_balance';
import { gonpay } from "./gonpay";
import { imas } from "./imas";

export const resourcesConfig = {
    agent,
    audit_log,
    account_category,
    account_category_year_balance,
    account_registry,
    amortization,
    article,
    article_material,
    article_test,
    article_operation,
    article_operation_production_comment,
    assets,
    body_pressure_point,
    calendar,
    calendar_integrations_controller,
    create_online_meeting,
    camera,
    cash_register,
    consumer_booking,
    company_account,
    confirmation,
    corporate_namespace,
    currency,
    customer,
    development_project,
    device,
    discount_tag,
    dynamic_table,
    document,
    element,
    event,
    event_link,
    employee,
    employee_contract,
    employee_document,
    entrance_card,
    entrance,
    eshop,
    expression,
    exception_log,
    feature,
    file,
    fiscal,
    general_ledger_record,
    imas,
    inventorizationByDate: inventorization_by_date,
    invoice,
    listener_subscriber,
    loose_relation,
    login_block,
    loyalty_card,
    maintenance,
    material,
    material_category,
    material_quant,
    payment,
    purchase: purchase,
    material_type,
    measurement_result,
    notification,
    ocr,
    online_payments,
    operation,
    order,
    order_product,
    order_proposal,
    order_proposal_parameter,
    order_proposal_product,
    order_template,
    payment_coupon,
    payment_provider,
    paypal,
    parameter,
    pos_account,
    gonpay,
    printer,
    product,
    product_container,
    production_operation,
    production,
    production_parameter,
    production_product,
    quant_event,
    report_sales,
    report_materials,
    sale_event,
    sale_opportunity,
    shipment,
    shipment_container,
    shipment_product,
    shop,
    'shop-cart': shopcart,
    supplier,
    tax,
    ledger,
    system_options,
    stripe,
    smart_tag_product,
    template,
    user,
    user_menu,
    'v2-auth': v2auth,
    workgroup,
    workplace,
    workshift,
    workplace_employee,
    widget,
    container,
    permission: permission,
    serv: server,
    server,
    tag,
    translation,
    'public/container_status': public_container_status,
};
