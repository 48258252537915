import { NgModule } from '@angular/core';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [TooltipDirective],
  declarations: [],
  exports: [TooltipDirective],
})

export class TooltipDirectiveModule {}
