import {
    AfterViewInit,
    Component,
    OnInit,
} from '@angular/core';
import { FilterService } from '@proman/services/filter.service';
import moment from 'moment';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { extractParamValue, get, safeMap } from "@proman/utils";
import {
  ArticleMaterial, ArticleOperationWorkplace, Customer, LoginBlockFile, MaterialSupplier,
  Operation,
  Parameter,
  Specialisation, SupplierMaterialFormat,
  Workgroup,
  Workplace
} from '@proman/interfaces/entity-interfaces';
import { Entity, EntityInterface, EntityNameType } from '@proman/services/entity.service';
import { Action, SelectOption } from '@proman/interfaces/object-interfaces';
import { DynamicComponent } from "@proman/list-manager/list-manager-dynamic-template.component";
import { PromanDialogService } from '@proman/dialog/proman-dialog.service';
import { InputDialogComponent } from '@proman/shared-dialogs/dialogs/input-dialog.component';

@Component({
  selector: 'pm-material-supplier-row',
  template: `
    <div fxLayout="row" fxLayoutAlign="start center">
      <pro-thumbnail [file]="item.supplier?.logo" size="32" class="RightMargin"></pro-thumbnail>
      <span>{{ item.supplier?.name }}</span>
      <pro-text-simple [value]="item.code" [config]="{ type: 'text' }" (onChange)="setCode($event)"></pro-text-simple>
      @if (item.code) {
        @if (item.supplierMaterialFormats?.length > 0) {
          @for (format of item.supplierMaterialFormats; track format) {
            <pm-material-type-format [item]="format" (onChange)="updateFormat(format, $event)"></pm-material-type-format>
          }
        } @else {
          <pro-btn icon="plus" theme="accent" (onClick)="createFormats()"></pro-btn>
        }
      }
    </div>
  `,
  styles: `
    span {
      text-align: center;
    }

    pro-text-simple {
      margin-left: 1em;
      width: 20%;
    }
  `
})

export class MaterialSupplierRowTemplateComponent extends DynamicComponent {
  item: MaterialSupplier;
  isCreate: boolean;
  entityInterface: EntityInterface<MaterialSupplier>;
  formatEntityInterface: EntityInterface<SupplierMaterialFormat>;

  constructor(private Entity: Entity) {
    super();
    this.entityInterface = this.Entity.get('material_supplier');
    this.formatEntityInterface = this.Entity.get('supplier_material_format');
  }

  setCode(code: string) {
    this.entityInterface.update({ id: this.item.id, code }).then(() => this.item.code = code);
  }

  createFormats() {
    const promises: Promise<unknown>[] = [];
    this.item.supplierMaterialFormats = [];
    this.item.material.materialFormats.forEach((mf) => {
      promises.push(this.formatEntityInterface.create({ materialSupplier: this.item.id, materialFormat: mf.id, value: '', parameter: mf.materialTypeFormat?.parameter?.id }));
    })

    Promise.all(promises).then(() => {
      this.entityInterface.get({ id: this.item.id, join: ['supplierMaterialFormats', 'supplierMaterialFormats.materialFormat'] }).then((response) => this.item = response);
    })
  }

  updateFormat(materialFormat: SupplierMaterialFormat, value: string) {
    this.Entity.get('supplier_material_format')
      .update({ id: materialFormat.id, value })
      .then(async () => {
        await this.formatEntityInterface.get({ id: materialFormat.id })
          .then((response) => {
            if (response) materialFormat = response;
          });
      });
  };
}

@Component({
  selector: 'pm-analyzed-items-row',
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <pro-autoc [fxFlex]="hasMaterialType ? 20 : 25" [value]="item[config.searchEntity]" [config]="{ entity: config.searchEntity }" (onChange)="item[config.searchEntity] = $event"></pro-autoc>
      <pro-text-simple [fxFlex]="hasMaterialType ? 20 : 25" [value]="item.quantity" [config]="{ label: 'quantity' }" (onChange)="item.quantity = $event"></pro-text-simple>
      <pm-price [fxFlex]="hasMaterialType ? 20 : 25" [value]="item.unitPrice" [config]="{ label: 'price' }" (onChange)="item.unitPrice = $event"></pm-price>
      <pro-autoc [fxFlex]="hasMaterialType ? 20 : 25" [value]="item.vat" [config]="{ entity: 'vat', label: 'vat'}" (onChange)="item.vat = $event"></pro-autoc>
      @if (hasMaterialType) {
        <pro-autoc [fxFlex]="hasMaterialType ? 20 : 25" [value]="item.materialType" [config]="{ entity: 'material_type', label: 'type'}" (onChange)="item.materialType = $event"></pro-autoc>
      }
    </div>
  `
})

export class AnalyzedItemsRowComponent extends DynamicComponent implements OnInit {
  hasMaterialType: boolean;

  constructor(private Entity: Entity) {
    super();
  }

  ngOnInit() {
    // eslint-disable-next-line no-prototype-builtins
    this.hasMaterialType = this.item.hasOwnProperty('materialType');
  }
}

@Component({
  selector: 'pm-login-block-files-row',
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{ item.file?.name }}</span>
      <div fxLayout="row">
        <pro-btn [icon]="'signal-stream'" [tooltip]="'is_a_stream'" [theme]="item.file?.isStream ? 'accent' : 'grey'" (onClick)="changeStream(!item.file?.isStream)"></pro-btn>
      </div>
    </div>
  `,
  styles: `
    span {
      text-align: center;
    }
  `
})

export class LoginBlockFilesRowComponent extends DynamicComponent {
  item: LoginBlockFile;

  constructor(private Entity: Entity) {
    super();
  }

  changeStream = (value: boolean) => {
    if (this.item.file) {
      this.Entity.get('file').update({
        id: this.item.file?.id,
        isStream: value
      }).then(() => this.item.file.isStream = value);
    }
  }
}

@Component({
    selector: 'pm-holiday-row',
    template: `<div>{{ item.month | proZeroPad }} - {{ item.day | proZeroPad }}</div>
    `
})
export class HolidayRowTemplateComponent extends DynamicComponent {}

@Component({
    selector: 'pm-article-history-row',
    template: `[{{ item.date | proDateTime }}] <span [innerHTML]="item.text"></span>
    `
})
export class ArticleHistoryRowTemplateComponent extends DynamicComponent {}

@Component({
    selector: 'pm-workgroup-workplaces-row',
    template: `<div fxLayout="column">
        <div><b style="font-size: 1.2em">{{ item.name  }}</b></div>
        <div *ngFor="let workplace of item.workplaces">

            └ <a [routerLink]="'Workplace' | pmSref: workplace.id"> {{  workplace.name  }} </a>
         </div>
    </div>
    `
})
export class WorkgroupWorkplacesTemplateComponent extends DynamicComponent {
    item: Workgroup;
}

@Component({
  selector: 'pm-workgroup-specialisations-row',
  template: `<div *ngIf="item && item.role" fxLayout="column">
             <a [routerLink]="'RoleSpecialisation' | pmSref: [item.role.id, item.id]"> {{  item.name  }} </a>
    </div>
    `
})
export class WorkgroupSpecialisationsTemplateComponent extends DynamicComponent {
  item: Specialisation;
}

@Component({
  selector: 'pm-resources-workgroup-operations-row',
  template: `
      <div fxLayout="column"
           style="overflow: scroll">
          <div class="ListManager__itemAlias">{{ getAlias(item) }}</div>
          <div class="ListManager__itemName" [ngClass]="{ 'List-row--extra': getAlias(item) }" fxLayout="row" fxLayoutAlign="start center">
              <pro-thumbnail *ngIf="config.image"
                             [file]="item[config.image]"
                             size="32"
                             class="RightMargin"
              >
              </pro-thumbnail>
              <ng-container *ngIf="config.state">
                  <a [routerLink]="config.state |pmSref: item.id ">{{ getName(item) }}</a>
              </ng-container>
              <ng-container *ngIf="!config.state">{{ getName(item) }}</ng-container>
          </div>
      </div>
  `
})

export class ResourcesWorkgroupOperationsTemplateComponent extends DynamicComponent {
  item: any;

  getName(item: any) {
    return get(item, this.config.namePath || 'name');
  }

  getAlias(item: any) {
    return get(item, this.config.aliasPath || 'alias');
  }
}

@Component({
    selector: 'pm-agent-customer-row',
    template: `<div fxLayout="column">
        <div>
            {{ item.alias  }}
            <ng-container *ngIf="item.country">
                (
                {{ item.country.name }}
                <ng-container *ngIf="item.country?.regions?.length">
                   | {{ item.country.regions[0].name }}
                </ng-container>
                )
            </ng-container>

        </div>
        <div><a [routerLink]="'Customer'|pmSref: item.id:'customer.edit'">{{ item.name }}</a></div>
    </div>
    `
})
export class AgentCustomerTemplateComponent extends DynamicComponent {
    item: Customer;
}

@Component({
    selector: 'pm-time-tag-row',
    template: `<span>{{ item.name }}</span> <span [innerHTML]="getItemData()"></span>
    `
})
export class TimeTagRowTemplateComponent extends DynamicComponent {

    constructor(
        private Filter: FilterService,
    ) {
        super();
    }

    getItemData() {
        let item = this.item;

        let result = ' | ';

        const dayNames = this.Filter.translate('_day_names_short').split(' ');

        if (item.day1) result += `${dayNames[1]} `;
        if (item.day2) result += `${dayNames[2]} `;
        if (item.day3) result += `${dayNames[3]} `;
        if (item.day4) result += `${dayNames[4]} `;
        if (item.day5) result += `${dayNames[5]} `;
        if (item.day6) result += `${dayNames[6]} `;
        if (item.day7) result += `${dayNames[0]} `;

        if (item.startTime) {
            const startTime = moment.utc(item.startTime, 'HH:mm:ss').add(moment().utcOffset(), 'm').format('HH:mm:ss');
            const endTime = moment.utc(item.endTime, 'HH:mm:ss').add(moment().utcOffset(), 'm').format('HH:mm:ss');
            result += `${startTime} - ${endTime}`;

        }

        return result;
    }

}

@Component({
    selector: 'pm-name-color',
    template: `
        <div fxLayout="row" fxLayoutAlign="start center">
            <span class="SidePadding">
                <span *ngIf="item.alias" class="Alias" > {{ item.alias }} - </span>
                {{ item.name }} </span>
            <pro-color [value]="item.color"></pro-color>
        </div>
    `,
    styles: [`
        .Alias {
            color :#626262;
        }
    `]
})
export class NameColorTemplateComponent extends DynamicComponent {}

@Component({
    selector: 'pm-article-operation-workplace-template',
    template: `

        <mat-expansion-panel (opened)="item._opened = true"
                             (closed)="item._opened = false">
            <mat-expansion-panel-header>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="noshrink">
                    <span *ngIf="!item._opened">{{ item.workplace.name }}</span>

                    <a *ngIf="item._opened"
                       [routerLink]="'Workplace' | pmSref: item.workplace.id: 'workplace.display'" proClickStopPropagation>
                        {{ item.workplace.name }}</a>

                    <div fxFlex></div>
                    <pro-checkbox [config]="{ label: 'plan_automatically' }"
                               [value]="item.isEnabled"
                               (onChange)="actions[3](item, $event)"
                               proClickStopPropagation></pro-checkbox>
                    <pro-btn (onClick)="actions[4](item)"
                            icon="times"
                            proClickStopPropagation
                            theme="warn"></pro-btn>
                </div>
            </mat-expansion-panel-header>

            <pro-expression-expression [value]="item.timeExpression"
                [function]="item.timeFunction"
                [fallbackValue]="item.workplace.timeExpression"
                [fallbackFunction]="item.workplace.timeFunction"
                [config]="{ label: 'time_expression' }"
                (onChange)="updateExpression('article_operation_workplace', item, 'time', $event)"></pro-expression-expression>

            <pro-expression-expression [value]="item.costExpression"
                [function]="item.costFunction"
                [fallbackValue]="item.workplace.costExpression"
                [fallbackFunction]="item.workplace.costFunction"
                [config]="{ label: 'cost_expression' }"
                (onChange)="updateExpression('article_operation_workplace', item, 'cost', $event)"></pro-expression-expression>

            <div fxLayout="row"
                 fxLayoutAlign="start center">
                <pro-label>{{ 'specialisations' | translate }}</pro-label>
                <pro-btn (onClick)="actions[5]($event, item)"
                        icon="plus"
                        theme="accent"></pro-btn>
            </div>
            <div *ngIf="item.workplaceSpecialisations.length" fxLayout="column">
                <mat-list-item class="secondary-button-padding"
                               *ngFor="let workplaceSpecialisation of item.workplaceSpecialisations; let $index = index;">
                    <div fxLayout="column">
                        <div fxLayout="row"
                             fxLayoutAlign="space-between center">
                            <p>
                                <a [routerLink]="'RoleSpecialisation'| pmSref: [workplaceSpecialisation.specialisation.role.id, workplaceSpecialisation.specialisation.id]"> {{ workplaceSpecialisation.specialisation.name }} </a>
                            </p>
                            <pro-btn (onClick)="actions[2]($index, item.workplaceSpecialisations)"
                                    icon="trash"
                                    theme="warn"></pro-btn>
                        </div>
                        <div>
                            <pro-expression-expression [value]="workplaceSpecialisation.timeExpression"
                                  [function]="workplaceSpecialisation.timeFunction"
                                  [fallbackValue]="workplaceSpecialisation.specialisation.timeExpression"
                                  [fallbackFunction]="workplaceSpecialisation.specialisation.timeFunction"
                                  [config]="{ label: 'time_expression' }"
                                  (onChange)="updateExpression('article_operation', workplaceSpecialisation, 'time', $event)">
                            </pro-expression-expression>

                            <pro-expression-expression [value]="workplaceSpecialisation.costExpression"
                                  [function]="workplaceSpecialisation.costFunction"
                                  [fallbackValue]="workplaceSpecialisation.specialisation.costExpression"
                                  [fallbackFunction]="workplaceSpecialisation.specialisation.costFunction"
                                  [config]="{ label: 'cost_expression' }"
                                  (onChange)="updateExpression('article_operation', workplaceSpecialisation, 'cost', $event)">
                            </pro-expression-expression>
                        </div>
                    </div>
                </mat-list-item>
            </div>
            <pro-no-records *ngIf="!item.workplaceSpecialisations.length"></pro-no-records>

        </mat-expansion-panel>
    `
})
export class ArticleOperationWorkplaceTemplateComponent extends DynamicComponent {

    constructor(private Entity: Entity) {
        super();

    }

    updateExpression = (entity: string, item: ArticleOperationWorkplace, key: string, value: any) => {
      console.log(entity, item);
      if (entity === 'article_operation') {
        if (value?.id) {
          this.Entity.get('article_operation_workplace_specialisation')
            .update({
              id: item.id,
              [key + 'Expression'] : null,
              [key + 'Function'] : value.id,
            })
            .then(() => {
              item[key + 'Expression'] = null;
              item[key + 'Function'] = value;
            });
        } else {
          this.Entity.get('article_operation_workplace_specialisation')
            .update({
              id: item.id,
              [key + 'Function'] : null,
              [key + 'Expression'] : value,
            })
            .then(() => {
              item[key + 'Function'] = null;
              item[key + 'Expression'] = value;
            });
        }
      } else {
        if (value?.id) {
          this.Entity.get('article_operation_workplace')
            .update({
              id: item.id,
              [key + 'Expression'] : null,
              [key + 'Function'] : value.id,
            })
            .then(() => {
              item[key + 'Expression'] = null;
              item[key + 'Function'] = value;
            });
        } else {
          this.Entity.get('article_operation_workplace')
            .update({
              id: item.id,
              [key + 'Function'] : null,
              [key + 'Expression'] : value,
            })
            .then(() => {
              item[key + 'Function'] = null;
              item[key + 'Expression'] = value;
            });
        }
      }
    }
}

@Component({
    selector: 'pm-role-specialisation-table-filter-template',
    template: `
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <pro-editable-container label="name">
                {{ item.table || item.entity }}
            </pro-editable-container>
            <pro-editable-container label="filter">
                {{ item.filter }}
            </pro-editable-container>
        </div>
    `
})
export class RoleSpecialisationTableFilterTemplateComponent extends DynamicComponent {}

@Component({
    selector: 'pm-toobar-action-template',
    template: `
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <pm-switch [value]="!item.hidden"
                     (onChange)="setHideAction($event)"></pm-switch>
            <pro-btn [icon]="item.icon"
                     [theme]="item.theme"
            ></pro-btn>
            <span>
                {{ item.tooltip | translate }}
            </span>

        </div>
    `
})
export class ToolbarActionTemplateComponent extends DynamicComponent {
  input: Action;

  setHideAction(value: boolean) {
    this.item.hidden = !value;
  }

}

@Component({
    selector: 'pm-calendar-list-template',
    template: `
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxFlex>
                <span class="SidePadding">{{ item.name }} </span>
            </div>
            <div fxFlex>
                <pro-editable-container label="type">
                    {{ item.type | translate }}
                </pro-editable-container>
            </div>
            <div fxFlex>
                <pro-checkbox [value]="item.showUnassignedColumn"
                           (onChange)="updateUnassigned($event)"
                           [config]="{ label: 'unassigned_jobs_column' }"></pro-checkbox>
            </div>
            <div fxFlex>
                {{ info }}
            </div>

        </div>
    `
})
export class CalendarListTemplateComponent extends DynamicComponent implements OnInit {
    info: string;

    constructor(
        private Entity: Entity,
    ) {
        super();
    }

    ngOnInit(): void {
        const type = this.item.type;

        if (['specialisations', 'workplaces', 'operations', 'workgroups'].includes(type)) {
            const entityMap: { [key: string]: EntityNameType } = {
                specialisations: 'specialisation',
                workplaces: 'workplace',
                operations: 'operation',
                workgroups: 'workgroup',
            };
            this.Entity.get(entityMap[type])
                .search({ id: this.item.objectIds })
                .then((response: Specialisation[]|Workplace[]|Operation[]|Workgroup[]) => {
                    this.info = safeMap(response, 'name').join(', ');
                });
        }

    }

    updateUnassigned = (value: boolean) =>
        this.Entity.get('calendar').update({ id: this.item.id, showUnassignedColumn: value });

}

@Component({
    selector: 'pm-article-material-dynamic-template',
  template: `
    <div fxLayout="row warp" fxLayoutAlign="start start" fxLayoutGap="8px" fxFlex="noshrink" class="Width100">

      <pro-autoc [value]="item.material"
                 (onChange)="actions[1](item, 'material', $event)"
                 [config]="{ label: 'material', entity: 'material', cache: false }"
                 [disabled]="config.noAdd"
                 fxFlex="20 0 125px"></pro-autoc>

      <pm-txt [config]="{ label: 'description' }"
              [value]="item.description"
              (onChange)="actions[0](item, 'description', $event)"
              [disabled]="config.noAdd"
              fxFlex="20 0 125px"></pm-txt>

      <pm-txt [config]="{ label: 'filter' }"
              [value]="item.filter"
              (onChange)="actions[0](item, 'filter', $event)"
              [pmOverlay]="overlayData"
              [disabled]="config.noAdd"
              fxFlex="20 0 100px"></pm-txt>

      <pro-btn (onClick)="actions[2](item)"
               icon="filter"
               size="1x"
               [tooltip]="'filter' | translate"
               [disabled]="config.noAdd"
               theme="accent"
               fxFlex="noshrink"></pro-btn>
      <pro-btn (onClick)="bruttoNettoCalculation(item)"
               icon="calculator" size="1x"
               [tooltip]="'calculate_brutto_netto' | translate"
               [disabled]="config.noAdd"
               theme="accent"
               fxFlex="noshrink"></pro-btn>
      <pm-txt [config]="{ label: 'additional_ratio_netto_brutto', parseNumber: true }"
              [value]="item.finalCoefficient"
              (onChange)="actions[0](item, 'finalCoefficient', $event)"
              [disabled]="config.noAdd"
              fxFlex="5 0 50px"></pm-txt>
      <pm-txt [config]="{ label: 'standard_quantity', parseNumber: true }"
              [value]="item.standardQuantity"
              (onChange)="actions[0](item, 'standardQuantity', $event)"
              [disabled]="config.noAdd"
              fxFlex="5 0 50px"></pm-txt>

      <pm-txt [config]="{ label: '' }"
              [value]="item.material?.materialType?.unit"
              [disabled]="true"
              fxFlex="2 0 20px"></pm-txt>

      <pro-select [value]="item.amountPerPiece"
                  [config]="{ label: 'amount_per_piece', key: 'id' }"
                  [options]="amountPerPieceOptions"
                  (onChange)="actions[0](item, 'amountPerPiece', $event)"
                  fxFlex="10 0 75px"></pro-select>

      <pro-expression-expression
        [config]="{ label: 'quantity_expression' }"
        [value]="item.quantityExpression"
        [function]="item.quantityFunction"
        [item]="item"
        [disabled]="config.noAdd"
        (onChange)="updateExpression(item, 'quantity', $event)"
        fxFlex="20 0 150px"></pro-expression-expression>

      <div fxLayout="row" class="Padding-16" fxFlex="5 0 90px">
        <pro-checkbox
          [config]="{ label: '', icon: 'calendar-check', tooltip: ( 'auto_reserve' | translate ), iconOnly: true}"
          [value]="item.autoReserve"
          (onChange)="flipReserveCredit(item, $event, 'autoReserve', 'autoMoveToProduction')"></pro-checkbox>

        <pro-checkbox
          [config]="{ label: '', icon: 'arrow-alt-right', tooltip: ( 'auto_move_to_production' | translate ), iconOnly: true}"
          [value]="item.autoMoveToProduction"
          (onChange)="flipReserveCredit(item, $event, 'autoMoveToProduction', 'autoReserve')"></pro-checkbox>
      </div>

    </div>
  `
})

export class ArticleMaterialDynamicTemplateComponent extends DynamicComponent implements AfterViewInit {
  overlayData: any = { type: 'button', data: null };
  item: ArticleMaterial;
  amountPerPieceOptions: SelectOption[];

  constructor(
    private ParametersOptions: ParametersOptionsService,
    private Filter: FilterService,
    private Dialog: PromanDialogService,
    private Entity: Entity,
  ) {
    super();

    this.amountPerPieceOptions = [
      { id: true, name: this.Filter.translate('quantity_per_piece') },
      { id: false, name: this.Filter.translate('production_quantity') }
    ] as unknown as SelectOption[];
  }

  ngAfterViewInit() {
    const param = extractParamValue(this.item.filter);

    if (this.item && param) {

      this.ParametersOptions
        .search({ entity: 'parameter', entityParams: { id: param } })
        .then((response: Parameter[]) => {
          if (response.length) this.overlayData = { type: 'button', data: response[0].name };
        });

    }

  }

  bruttoNettoCalculation = (item: any) => {
    //Nes kai kurie zmones nemoka elementarios matematikos.
    const parameters: any[] = [
      {
        name: 'netto',
        key: 'netto',
        type: 'number',
        config: { required: true },
      },
    ];

    if (item?.material?.materialType?.unit) {
      const units = [item.material.materialType.unit];
      parameters.push({
        name: 'unit',
        key: 'unit',
        type: 'select',
        disabled: true,
        config: { options: units, disableSearch: true },
        value: item.material.materialType.unit,
      })
    }

    this.Dialog.open(InputDialogComponent, {
      header: 'input_brutto_netto',
      mainField: {
        name: 'brutto',
        key: 'brutto',
        type: 'number',
        config: { required: true },
      },
      parameters,
    }).then((result: { brutto: string, netto: string }) => {
      const finalCoefficient = parseFloat(result.netto) / parseFloat(result.brutto);
      this.Entity.get('article_material').update({ id: item.id, standardQuantity: result.brutto, finalCoefficient }).then(() => {
        item.finalCoefficient = finalCoefficient;
        item.standardQuantity = result.brutto;
      });
    });
  };

  updateExpression = (item: any, key: string, value: any) => {
    if (value?.id) {
      this.actions[0](item, key + 'Function', value.id);
      this.actions[0](item, key + 'Expression', null);
      item[key + 'Function'] = value;
      item[key + 'Expression'] = null;
    } else {
      this.actions[0](item, key + 'Function', null);
      this.actions[0](item, key + 'Expression', value);
      item[key + 'Function'] = null;
      item[key + 'Expression'] = value;
    }
  };

  flipReserveCredit = (item: any, event: any, first: any, second: any) => {
    if (item[first]) this.actions[0](item, first, false);
    this.actions[0](item, first, true);
    this.actions[0](item, second, false);
  };
}
