import { EntityInterface } from '../services/entity.service';
import { PromanFile } from '@proman/interfaces/entity-interfaces';

export const imas = {
    name: 'imas',
    params: {
        entityConfig: {
            name: 'imas',
            post: [
                'generate_certificate',
                'imas_upload',
                'imas_check_state',
                'imas_submit_registry',
                'imas_remove_registry',
                'imas_get_registry_status',
            ]
        }
    }
};

export interface ImasEntityInterface extends EntityInterface {
    generate_certificate: () => Promise<any>;
    imas_upload: (file: PromanFile) => Promise<any>;
    imas_check_state: () => Promise<any>;
    imas_submit_registry: () => Promise<any>;
    imas_remove_registry: () => Promise<any>;
    imas_get_registry_status: () => Promise<any>;
}
