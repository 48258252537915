import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { InputDialogComponent } from '@proman/shared-dialogs/dialogs/input-dialog.component';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { ParametersOptionsService } from '@proman/services/parameters-options.service';
import { ACL, PermissionType } from '@proman/services/acl.service';
import { ConsumerBookingDialogComponent } from '../../orders/components/consumer-booking-dialog.component';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { ENTITY_TO_STATE } from '@proman/resources/entity_to_state';
import { isSmarton } from "@proman/utils";
import { PromanRequest } from '@proman/interfaces/object-interfaces';
import { Store } from '@ngrx/store';
import { Customer, LooseRelation, SystemOptions } from '@proman/interfaces/entity-interfaces';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { getSystemOptions } from '@proman/store/system-options';
import { LooseRelationEntityInterface } from '@proman/resources/loose_relation';
import { UiPreferencesService } from "@proman/services/ui-preferences.service";
import { InlineListService } from '@proman/inline-list/inline-list.service';

const STATES = {
    sale_opportunity: {
        name: 'SaleOpportunity',
        key: 'saleOpportunityId'
    },
    order: {
        name: 'OrderInfo',
        key: 'orderId'
    },
    production: {
        name: 'Production',
        key: 'productionId'
    },
    article: {
        name: 'Article',
        key: 'articleId'
    },
    development_project: {
        name: 'DevelopmentProject',
        key: 'developmentProjectId'
    },
    article_test: {
        name: 'ArticleTest',
        key: 'articleTestId'
    },
    consumer_booking: {
        name: 'ConsumerBookings',
        key: '',
        nameKey: 'comment',
        dialog: ConsumerBookingDialogComponent,
        extraGet: { join: ['files'] }
    },
    order_proposal: {
      name: 'OrderCreate',
      key: 'OrderCreate'
    },
    sale_event: {
        name: 'SalesEvent',
        key: 'saleEventId'
    },
};

@Component({
    selector: 'pm-loose-relation',
    template: `
        <div class="LooseRelations" *ngIf="item && entityName && relations" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start center">
                <pro-label>
                    {{ 'relations' | translate }}
                </pro-label>

                <pro-btn *ngIf="!disabled"
                        icon="plus"
                        theme="accent"
                        (onClick)="handleAdd($event)"
                        [tooltip]="'add' | translate"
                ></pro-btn>
            </div>
            <hr>


            <div *ngFor="let relation of relations"
                 class="Relation"
                 fxLayout="row"
                 fxLayoutAlign="space-between center"
                 fxFlex="noshrink">

                <span class="relationContent"
                      [proOverlay]="{ type: 'text', data: relation.b._name }">
                    <span [ngStyle]="relation.style">
                        <fa [name]="relation.looseRelationType.icon" [size]="''"></fa>
                        {{ relation._relation }}
                    </span>

                    <a href="#" (click)="handleRelationClick(relation, $event); false">{{ relation.b._name | proMaxLength: 45 }} #{{relation.b.id}}</a>
                    ({{ relation.b.entityName | translate }})

                </span>

                <fa name="times"
                       [size]="''"
                       (click)="removeRelation(relation)"
                       class="Clickable warn"
                ></fa>

            </div>

            <pro-no-records *ngIf="!relations.length"></pro-no-records>

        </div>
    `,
    styles: [`
        .Relation {
            padding: 4px;
        }

        .warn {
            color: #F44336;
        }

        .dark-mode .warn {
            color: #ff6c6c;
        }

        .relationContent {
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})

export class LooseRelationComponent implements OnInit, OnChanges {
    @Input() item: { name: string; id: number; customer?: Customer };
    @Input() entityName: string;
    @Input() disabled: boolean;

    relations: LooseRelation[]
    looseRelationEntity: LooseRelationEntityInterface;
    _inited: boolean = false;
    systemOptions: SystemOptions;

    constructor(
        private cd: ChangeDetectorRef,
        private ACL: ACL,
        private Dialog: Dialog,
        private Entity: Entity,
        private Filter: FilterService,
        private InlineList: InlineListService,
        private PromanState: PromanStateService,
        private store: Store,
        private QueryExpression: QueryExpressionService,
        private ParametersOptions: ParametersOptionsService,
        private UiPrefs: UiPreferencesService,
    ) {
        this.looseRelationEntity = this.Entity.get('loose_relation');
        this.store.select(getSystemOptions)
            .subscribe((value) => this.systemOptions = value);
    }

    ngOnChanges() {
        if (this._inited) this.ngOnInit();
    }

    ngOnInit() {

        this.looseRelationEntity.getRelations({ tableName: this.entityName, entityId: this.item.id })
            .then((response) => {
                if (!response) return;
                this.relations = response;

                this.relations.forEach((item) => {
                    const fromState = STATES[item.fromTableName].name;
                    const fromParams = item.fromEntityId;

                    const toState = STATES[item.toTableName].name;
                    const toParams = item.toEntityId;

                    const from = item.fromTableName === this.entityName && parseInt(item.fromEntityId) === this.item.id;

                    item._relation = item.looseRelationType[from ? 'fromName' : 'toName'];

                    item['a'] = {
                        entityName: from ?  item.fromTableName : item.toTableName,
                        id: from ? item.fromEntityId : item.toEntityId,
                        name: from ? fromState : toState,
                        params: from ? fromParams : toParams,
                        nameKey: STATES[from ?  item.fromTableName : item.toTableName].nameKey || 'name'
                    };

                    item['b'] = {
                        entityName: !from ?  item.fromTableName : item.toTableName,
                        id: !from ? item.fromEntityId : item.toEntityId,
                        name: !from ? fromState : toState,
                        params: !from ? fromParams : toParams,
                        nameKey: STATES[!from ?  item.fromTableName : item.toTableName].nameKey || 'name'
                    };

                    item.style = { color: `#${item.looseRelationType.color}` };

                    if (this.ACL.check(`${item.a.entityName}.view` as PermissionType) || (this.entityName === 'sale_opportunity' && this.ACL.check('sale_opportunity.view'))) {
                        this.ParametersOptions
                            .get({ entity: item.a.entityName as EntityNameType, entityParams: { id: item.a.id, select: [item.a.nameKey] } })
                            .then((response) => {
                                item.a._name = response[item.a.nameKey] || item.a.name;
                                this.cd.markForCheck();
                            })
                            .catch(() => {
                                item.a._name = '';
                            });
                    }

                    if (this.ACL.check(`${item.b.entityName}.view` as PermissionType) || (this.entityName === 'sale_opportunity' && this.ACL.check('sale_opportunity.view'))) {
                        this.ParametersOptions
                            .get({ entity: item.b.entityName as EntityNameType, entityParams: { id: item.b.id, select: [item.b.nameKey] } })
                            .then((response) => {
                                item.b._name = response[item.b.nameKey] || item.b.name;
                                this.cd.markForCheck();
                            })
                            .catch(() => {
                                item.b._name = '';
                            });
                    }

                });

                this.cd.markForCheck();

                this._inited = true;
            });
    }

    getOptions = (entity: EntityNameType) => {

        const getSearchRequest = (query: string) => {
            let searchRequest: PromanRequest<{}> = { search: { id: query, name: query }, limit: 50 };

            if (entity === 'consumer_booking') {
                searchRequest = { search: { id: query, name: query, comment: query, start: query }, limit: 50 };
            }

            if (entity !== 'article' && entity !== 'production') {
                if (!this.ACL.check('customer.view_all') && this.item.customer) {
                    searchRequest['customer.id'] = this.item.customer.id;
                } else if (this.systemOptions.projectsRelationsSameCustomer && this.item.customer) {
                    searchRequest['customer.id'] = this.item.customer.id;
                }
            }

            return searchRequest;

        };

        return (query: string) => this.Entity
            .get({ name: entity })
            .search(getSearchRequest(query))
            .then((response: Array<{ id: number; name?: string; comment?: string }>) => {
                if (entity === 'consumer_booking') {
                    response.forEach((item) => item.name = item.comment);
                }

                return response;
            });
    };

    handleAdd($event: Event) {
        let defaultVal: any;
        let data: any = [
            { id: 'sale_opportunity', name: this.Filter.translate('sale') },
            { id: 'development_project', name: this.Filter.translate('development') },
        ];

        if (this.ACL.check('order.view')) data.push({ id: 'order', name: this.Filter.translate('order') });
        if (this.ACL.check('order_proposal.view')) data.push({ id: 'order_proposal', name: this.Filter.translate('order_proposal') });
        if (isSmarton()) data.push({ id: 'consumer_booking', name: this.Filter.translate('consumer_booking') });

        if (this.entityName === 'article_test') {
            if (this.ACL.check('article.view')) data.push({ id: 'article', name: this.Filter.translate('article') });
            if (this.ACL.check('production.view')) data.push({ id: 'production', name: this.Filter.translate('production') });
        }

        if (this.entityName === 'sale_opportunity') {
            data.push({ id: 'sale_event', name: this.Filter.translate('sale_event') });
        }

        this.Entity.get('loose_relation_type')
            .get()
            .then((response: any) => defaultVal = response);

        this.InlineList.show({
            data,
            event: $event,
            closeOnSelect: true,
            onSelect: (item: any) => {

                this.Dialog
                    .open(InputDialogComponent, {
                        mainField: { key: 'object', name: 'object', type: 'autocomplete', config: { getOptions: this.getOptions(item.id) } },
                        parameters: [{ key: 'type', name: 'type', type: 'autocomplete', config: { entity: 'loose_relation_type' }, value: defaultVal }],
                        header: 'add',
                        variant: 'add'
                    },
                        {
                            width: '500px'
                        })
                    .then((result: any) => {
                        if (!(result && result.type && result.object)) return;

                        this.Entity
                             .get('loose_relation')
                            .create({
                                fromTableName: this.entityName,
                                fromEntityId: this.item.id,
                                toTableName: item.id,
                                toEntityId: result.object,
                                looseRelationType: result.type
                            })
                            .then(() => this.ngOnInit());

                    });

            }
        });

    }

    removeRelation(item: any) {
        this.looseRelationEntity
            .remove({ id: item.id })
            .then(() => this.ngOnInit());
    }

    async handleRelationClick(relation: LooseRelation, event: MouseEvent) {

        if (!relation.b._name) return;

        const entity = relation.b.entityName;
        const state = ENTITY_TO_STATE[relation.b.entityName];

        const isNewTab = !!(event.ctrlKey || event.metaKey);

        if (!!state) {
            this.PromanState.to(state, relation.b.id, null, isNewTab);

        } else if (STATES[entity].dialog) {
            let item = await this.Entity.get(entity as EntityNameType).get(Object.assign(STATES[entity].extraGet || {}, { id: relation.b.id }));

            this.Dialog.open2(STATES[entity].dialog, { item }, { disableAutoFocus: true });

        } else if (relation.toTableName === 'order_proposal') {
          this.UiPrefs.set('activeOrderProposal', relation.b.id);
          this.PromanState.to('OrderCreate', null, null, isNewTab);
        }

    }

}
