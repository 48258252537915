import { Injectable } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { Subject } from 'rxjs';
import { snakeCase } from '@proman/utils';
import { TextPreviewDialogComponent } from '../../shared/components/text-preview-dialog.component';
import { getFieldValue } from '@proman/table/utils/tools';
import { Dialog } from '../../shared/services/dialog.service';
import { ButtonListener, EmailListener, PeriodicListener, TableListener } from '@proman/interfaces/entity-interfaces';
import moment from 'moment/moment';
import { TableButton } from '@proman/interfaces/object-interfaces';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    notificationEntity: any;
    objects: any;
    timeStamp: Subject<number> = new Subject<number>();

    constructor(private Entity: Entity, private Filter: FilterService, private Dialog: Dialog) {
        this.notificationEntity = this.Entity.get('notification');
    }

    toJsName = (name: string) => snakeCase(name);

    toJsTranslate = (name: string) => this.Filter.translate(this.toJsName(name));

    prepareField = (fieldData: any) => {
        return {
            id: fieldData.fieldName,
            type: fieldData.type,
            name: this.toJsTranslate(fieldData.fieldName),
            values: fieldData.values
        };
    };

    prepareAssociation = (associationData: any) => {
        return {
            id: associationData.fieldName,
            toOne: associationData.toOne,
            name: this.toJsTranslate(associationData.fieldName),
            className: associationData.className,
            fields: this.prepareAssociationFields(associationData)
        };
    };

    isDateType = (field: any) => field.type === 'date'

    prepareObject = (object: any) => {
        object.name = this.toJsTranslate(object.className);
        object.fields = object.fields.map(this.prepareField);
        object.dateFields = object.fields.filter(this.isDateType);
        object.associations = object.associations.map(this.prepareAssociation);

        return object;
    };

    prepareAssociationFields = (association: any) => {
        const assosiationFields: any = [];
        const objectItem = this.objects.Filter((object: any) => object.className === association.className)[0];

        if (!objectItem) return [];

        objectItem.fields.forEach((field: any) => {

            if (!field._translated) {
                field.name = this.toJsTranslate(association.className) + ' - ' + this.toJsTranslate(field.id);
                field._translated = true;

            }

            assosiationFields.push(field);
        });

        return assosiationFields;
    };

    getObjects = () => {
        return this.notificationEntity
            .getObjects()
            .then((response: any) => {
                this.objects = response;

                return response?.map(this.prepareObject);
            });
    };

    refresh() {
        this.timeStamp.next(new Date().getTime());
    }

    getTables = () => {
        return [
            'sale_opportunity_project',
            'purchase',
            'orders',
            'material_quants',
            'shipments',
            'productions',
            'events',
            'documents',
            'persons',
            'products',
            'employees',
            'development_project',
        ].map((item: string) => ({ id: item, name: this.Filter.translate(item) }))
    };

    readLastResult = (row: (PeriodicListener|TableListener|EmailListener|ButtonListener)) => {
        this.Dialog.open2(TextPreviewDialogComponent, {
            type: 'html',
            header: 'last_result',
            text: `<code>${row.lastResult}</code>`
        })
    }

    getActionValue = (row: any) => {
        if (!row.actionClass) return '';

        const splits = row.actionClass.split('\\');

        return splits[splits.length - 1];
    };
    fullDate = (date: string) => date ? moment(date).utcOffset(0).format('YYYY-MM-DD HH:mm:ss') : '';

    getBooleanIcon = (row: any, key: any) => {
        const value = getFieldValue(key, row);

        const icon = {
            icon: value ? 'check' : 'times',
            color: value ? 'green' : 'grey'
        };

        return {
            icon,
            text: ''
        };
    };

    getMultiselectEnabledButtons (entity: string, callback: () => void): TableButton[] {
        return [
            {
                action: 'update_status',
                acl: 'notification.create',
                theme: 'accent',
                label: 'enable',
                callback: (rows: (PeriodicListener|TableListener|EmailListener|ButtonListener)[]) => {
                    const promises: Promise<unknown>[] = [];
                    rows.forEach(row => {
                        promises.push(this.Entity.get(entity)
                          .update({
                              id: row.id,
                              enabled: true
                          }))
                    });
                    Promise.all(promises).then(() => callback());
                }
            },
            {
                action: 'update_status',
                acl: 'notification.create',
                theme: 'warn',
                label: 'disable',
                callback: (rows: (PeriodicListener|TableListener|EmailListener|ButtonListener)[]) => {
                    const promises: Promise<unknown>[] = [];
                    rows.forEach(row => {
                        promises.push(this.Entity.get(entity)
                          .update({
                              id: row.id,
                              enabled: false
                          }))
                    });
                    Promise.all(promises).then(() => callback());
                }
            },
        ];
    }
}
