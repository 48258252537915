import { NgModule } from '@angular/core';

import { Entity } from './services/entity.service';
import { ACL } from './services/acl.service';
import { RequestService } from './services/request.service';
import { PreferencesService } from './services/preferences.service';
import { UiPreferencesService } from './services/ui-preferences.service';
import { AuthService } from './services/auth.service';
import { QueryExpressionService } from './services/query-expression.service';
import { LocalStorageService } from './services/local-storage.service';
import { CookiesService } from './services/cookies.service';
import { WebsocketService } from './services/websocket.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CurrenciesService } from '@proman/services/currencies.service';
import { ToastService } from './services/toast.service';
import { CursorLoadingService } from '@proman/services/cursor-loading.service';
import { ModelService } from '@proman/services/model.service';
import { FilterService } from './services/filter.service';
import { ImagePathService } from './services/image-path.service';
import { OutsideClickDetectorService } from "@proman/services/outside-click-detector.service";
import { InlineListModule } from "@proman/inline-list/inline-list.module";
import { PrinterService } from '@proman/services/printer.service';
import { MasterRegistryService } from '@proman/services/master-registry.service';
import { HttpClientModule } from '@angular/common/http';
import { MqttModule } from 'ngx-mqtt';

@NgModule({
    imports: [
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument(),
        InlineListModule,
        HttpClientModule,
        MqttModule.forRoot({ connectOnCreate: false }),
    ],
    providers: [
        ACL,
        AuthService,
        CookiesService,
        CurrenciesService,
        Entity,
        LocalStorageService,
        PreferencesService,
        RequestService,
        QueryExpressionService,
        UiPreferencesService,
        WebsocketService,
        ToastService,
        ModelService,
        CursorLoadingService,
        FilterService,
        ImagePathService,
        OutsideClickDetectorService,
        PrinterService,
        MasterRegistryService,
    ],
    declarations: [],
    exports: []
})
export class PromanModule { }
