import {Customer, MaterialQuant, Order} from '../interfaces/entity-interfaces';
import { EntityInterface, EntityNameType } from '../services/entity.service';
import { date, Money } from '../interfaces/common.interface';

export interface OrderEstimateType {
    cost: Money;
    price: Money;
    production_finish: date;
    weight: number;
    quants: MaterialQuant[];
}

export const order = {
    name: 'order',
    params: {
        searchFields: [
            'name',
            'number',
            'customerNumber',
            'manager.name',
            'customer.name',
            'agent.name'
        ],
        get: {
            join: [
                'customer',
                'customer.defaultCarrier',
                'customer.agent',
                'customer.autoInvoiceTemplate',
                'customerEmployee',
                'agent',
                'participants',
                // 'customerFiles',
//                'shipments',
//                'shipments.carrier',
                'tags',
                'priority',
                'operations',
                'operations.comments',
                'operations.comments.author',
                'previewFile',
                'type',
                'subtype',
                'consumer',
            ],
            partialJoin: {
                'articles': ['weightUnit'],
                'manager': ['name'],
                // 'files.createdBy': ['name'],
                // 'customerFiles.createdBy': ['name'],
            }
        },
        agentGet: {
            join: [
                'customer',
                'customerEmployee',
                'agent',
                'carrier',
                'files',
                'shipments',
                'articles',
                'tags',
                'priority',
            ],
            partialJoin: {
                manager: ['id', 'name'],
            }
        },
        entityConfig: {
            name: 'order' as EntityNameType,
            autocompleteProperties: [
                'number',
                'name'
            ],
            defaultSort: {
                createdAt: 'desc'
            },
            get: [
                'getStatuses',
                'getQrCode',
                'getCustomerDiscountPercent',
                'getIncrementId',
                'getOrderShipments',
                'getValidatedTests',
            ],
            post: [
                'copy',
                'cancel',
                'finish',
                'confirmProducts',
                'suspend',
                'resume',
                'revertConfirmation',
                'updateAgentCommissionPercent',
                'updateAgentDiscountPercent',
                'updateProductsArticles',
                'sendToDevelopment',
                'finishDevelopment',
                'updateCustomer',
                'moveProducts',
                'calculateOrderFromOrderProducts',
                'setCustomerPrice',
                'createOrder',
                'updateType',
                'setIncrementId',
                'posCreate',
                'posRemove',
                'posUpdate',
                'posConfirmProducts',
                'posCreateProduction',
                'posConfirmTableProducts',
                'posRefreshProductMaterials',
                'mergeOrdersPos',
                'cancel',
                'posMoveProducts',
                'updateDiscountPercent',
                'posUpdateCustomer',
                'undelete',
                'addOrderParameter',
                'sendEmail',
                'sendConfirmation',
                'sendInvoices',
                'estimate',
                'importDefault',
                'reprintConfirmationCheck',
                'getInvalidatedProducts',
                'updateDiscountPercent',
                'reevaluateWeight',
                'sendOrderProductConfirmation',
            ],
            attrs: {
                CREATED: 'created',
                CONFIRMED_BY_CUSTOMER: 'confirmed_by_customer',
                CONFIRMED_BY_MANAGER: 'confirmed_by_manager',
                CONFIRMED_BY_TECHNOLOGIST: 'confirmed_by_technologist',
                STARTED: 'started',
                COMPLETE: 'complete',
                CANCELED: 'canceled',
                DELIVERING: 'delivering',
                SUSPENDED: 'suspended',
                IN_DEVELOPMENT: 'in_development',
                RESERVED: 'reserved',
            },
            uris: {
                importUrl: 'import'
            },
            QueryBuilder: {
                joinParameters() {
                    return {
                        join: [
                            'parameters',
                            'parameters.parameter'
                        ]
                    };
                },
                joinProducts() {
                    return {
                        join: [
                            'products',
                            'products.product',
                            'products.product.parameters',
                            'products.product.parameters.parameter',
                            'products.product.article'
                        ]
                    };
                }
            }
        },
        extraParameters: (customer: Customer) => {
            const extraParameters = {
                join: ['tags'],
                partialJoin: {
                    customer: ['alias'],
                    manager: ['name'],
                    shipments: ['date'],
                    agent: ['id'],
                    type: ['name'],
                    subtype: ['name'],
                    productions: ['quantity'],
                    documents: ['id', 'type'],
                },
                sort: { 'createdAt': 'desc', 'shipments.date': 'asc' },
                internal: false,
                translate: true
            };

            if (customer) extraParameters['customer.id'] = customer.id;

            return extraParameters;
        },
        orderStatuses: [
            'created',
            'started',
            'complete',
            'confirmed_by_customer',
            'confirmed_by_manager',
            'confirmed_by_technologist',
            'in_development',
            'reserved',
            'prepared',
            'delivering',
            'merged',
            'canceled',
            'suspended',
        ],
        orderStatusIcons: {
            created: { icon: 'sync', color: 'grey' },
            copied: { icon: 'copy', color: 'grey'},
            confirmed_by_customer: { icon: 'comment-check', color: 'yellow' },
            confirmed_by_manager: { icon: 'check-circle', color: 'blue' },
            confirmed_by_technologist: { icon: 'check-square', color: 'green' },
            started: { icon: 'play', color: 'blue' },
            complete: { icon: 'flag-checkered', color: 'green' },
            canceled: { icon: 'ban', color: 'red' },
            delivering: { icon: 'truck', color: 'green' },
            prepared: { icon: 'box', color: 'green' },
            partly_prepared: { icon: 'box', color: 'yellow' },
            suspended: { icon: 'pause', color: 'yellow' },
            in_development: { icon: 'lightbulb', color: 'yellow' },
            reserved: { icon: 'bars', color: 'grey' },
            merged: { icon: 'code-merge', color: 'blue' },
            confirmed: { icon: 'check', color: 'green' }, // production status
            active: { icon: 'play', color: 'grey' }, // devProject status
            won: { icon: 'thumbs-up', color: 'grey' }, // devProject status
            archived: { icon: 'flag-checkered', color: 'grey' }, // devProject status
        },
        numberingStrategies: [
            'default',
            'sequence',
            'global_prefix',
            'customer_prefix'
        ]
    }
};

export interface OrderEntityInterface extends EntityInterface<Order> {
    CREATED: 'created';
    CONFIRMED_BY_CUSTOMER: 'confirmed_by_customer';
    CONFIRMED_BY_MANAGER: 'confirmed_by_manager';
    CONFIRMED_BY_TECHNOLOGIST: 'confirmed_by_technologist';
    STARTED: 'started';
    COMPLETE: 'complete';
    CANCELED: 'canceled';
    DELIVERING: 'delivering';
    SUSPENDED: 'suspended';
    IN_DEVELOPMENT: 'in_development';
    RESERVED: 'reserved';
    PREPARED: 'prepared';
    PARTLY_PREPARED: 'partly_prepared';
    importUrl: 'import';
    getIncrementId: () => Promise<{ id: number|string }>;
    setIncrementId: (data: { startId: number|string }) => Promise<any>;
    createOrder: (data: { manager: number; quantity: number|string; products: number[] }) => Promise<any>;
    finishDevelopment: (data: { id: number; articleId: number }) => Promise<any>;
    setCustomerPrice: (data: { id: number; customerPrice: Money }) => Promise<any>;
    sendToDevelopment: (data: { id: number; deadline: string; objective: string }) => Promise<any>;
    copy: (data: { id: number; date: string; manager: number; preserveQuantities: boolean; copyComments: boolean; type: number }) => Promise<any>;
    updateType: (data: { id: number; orderType: number }) => Promise<any>;
    updateCustomer: (data: { id: number; customer: number }) => Promise<any>;
    updateDiscountPercent: (data: { id: number; discountPercent: number|string, is_pos?: boolean }) => Promise<any>;
    confirmProducts: (data: { id: number|number[] }) => Promise<any>;
    moveProducts: (data: { id: number; orderProducts: number[] }) => Promise<any>;
    revertConfirmation: (data: { id: number }) => Promise<any>;
    getOrderShipments: (data: { id: number }) => Promise<any>;
    finish: (data: { id: number; stopProductions?: boolean }) => Promise<any>;
    resume: (data: { id: number }) => Promise<any>;
    suspend: (data: { id: number }) => Promise<any>;
    cancel: (data: { id: number }) => Promise<any>;
    estimate: (data: { id: number }) => Promise<OrderEstimateType>;
    undelete: (data: { id: number }) => Promise<any>;
    getValidatedTests: (data: { id: number }) => Promise<any>;
    addOrderParameter: (data: { order: number; parameter: number }) => Promise<any>;
    import: (data: { orderId: any; dry: any }) => Promise<{ order: number }>;
    sendEmail: (data: { id?: number; document: number | number[]; template: number; subject: string; emails: string[] }) => Promise<any>;
    sendConfirmation: (data: { id?: number; order: number | number[]; template: number; subject: string }) => Promise<any>;
    sendInvoices: (data: { documents: number | number[], template:number }) => Promise<any>;
    posRemove: (data: { id: number; device?: number; return_materials?: boolean }) => Promise<unknown>;
    posCreateProduction: (data: { id: number; version?: unknown }) => Promise<unknown>;
    posCreate: (data: { customer: number; consumer: number; numberOfVisitors: number; [key: string]: unknown }) => Promise<Order[]>;
    posConfirmProducts: (data: { id: number; version?: number|string }) => Promise<unknown>;
    posConfirmTableProducts: (data: { order_consumer_id: number;  }) => Promise<Order[]>;
    mergeOrdersPos: (data: { id: number; order: number|number[]; device?: number }) => Promise<unknown>;
    posMoveProducts: (data: {}) => Promise<void>;
    posUpdate: (data: {}) => Promise<void>;
    reprintConfirmationCheck: (data: { id: number }) => Promise<any>;
    getInvalidatedProducts: (data: { id: number[] }) => Promise<{ id: number }[]>;
    reevaluateWeight: (data: { ids: number[] }) => Promise<unknown>;
    sendOrderProductConfirmation: (data: { id: number, template: number, subject: string }) => Promise<unknown>;
    posRefreshProductMaterials: (data: { id: number }) => Promise<unknown>;
}
