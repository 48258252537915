import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { LocalStorageService } from '@proman/services/local-storage.service';
import { Product, ProductContainer } from '@proman/interfaces/entity-interfaces';
import { ToastService } from '@proman/services/toast.service';

@Component({
    selector: 'pm-product-container-register',
    template: `
        <pro-dialog-title title="new_container"></pro-dialog-title>
        <div mat-dialog-content>
            <pro-autoc [value]="name"
                       [config]="{ label: 'search', entity: 'product' }"
                       (onChange)="onSelect($event)"></pro-autoc>
            <ng-container *ngIf="container">

                <pm-txt [config]="{ label: 'product_quantity', validators: { number: true } }"
                        [value]="container.quantity"
                        [disabled]="!product"
                        (onChange)="set('quantity', $event)"></pm-txt>
                <pm-txt [config]="{ label: 'product_container_quantity', validators: { number: true } }"
                        [value]="container.batchTotal"
                        [disabled]="!product"
                        (onChange)="set('batchSize', $event)"></pm-txt>
                <pm-barcode></pm-barcode>
            </ng-container>
        </div>
        <pro-dialog-actions>
            <pro-btn *ngIf="showPrint()"
                    (onClick)="print()"
                    [label]="'print' | translate"
                    theme="accent"></pro-btn>
            <pro-btn *ngIf="showCopy()"
                    (onClick)="copy()"
                    [label]="'copy' | translate"
                    theme="accent"></pro-btn>
        </pro-dialog-actions>
    `
})

export class ProductContainerRegisterDialogComponent {
    barcodePrinterEntity: any;
    productContainerEntity: any;
    previousContainers: any;
    product: Product;
    container: ProductContainer|any;
    name: string;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: {
            product?: Product;
            quantity?: number;
            autoClose?: boolean;
            returned: boolean;
        },
        private Toast: ToastService,
        private Entity: Entity,
        private LocalStorage: LocalStorageService,
        private dialogRef: MatLegacyDialogRef<ProductContainerRegisterDialogComponent>,

    ) {
        this.barcodePrinterEntity = this.Entity.get('printer');
        this.productContainerEntity = this.Entity.get('product_container');

        if (data.product) this.onSelect(data.product);
        if (data.quantity) this.set('quantity', data.quantity);
    }

    print() {
        if (+this.container.batchTotal == 0 || +this.container.quantity == 0) {
            this.Toast.pop('warn', 'Quantity is 0');
            return;
        }

        console.log('PRODUCT: ', this.product);
        console.log('CONTAINER: ', this.container);
        console.log('PREVIOUS CONTAINERS: ', this.previousContainers);
        // this.productContainerEntity.createBatch({
        //         printer: this.LocalStorage.get('barcodePrinter'),
        //         barcode_template: this.LocalStorage.get('barcodeTemplate'),
        //         product: this.product.id,
        //         quantity: this.container.quantity,
        //         batchSize: this.container.batchTotal,
        //     })
        //     .then((response: any) => {
        //         this.previousContainers = response.data;
        //         if (this.data.autoClose) this.dialogRef.close(response);
        //     });
    }

    copy() {
        if (this.previousContainers) {
            this.barcodePrinterEntity.printProductContainer({
                'printer': this.LocalStorage.get('barcodePrinter'),
                'barcode_template': this.LocalStorage.get('barcodeTemplate'),
                'productContainer': this.previousContainers.map((container: any) => container.id)
            });
        }
    }

    showPrint = () => {
        return !!this.product;
    };

    showCopy = () => {
        return this.product && this.previousContainers && this.previousContainers.length;
    };

    onSelect = (product: any) => {
        this.name = product;

        this.previousContainers = [];

        this.product = product;
        this.container = {
            quantity: product.storedQuantity,
            batchTotal: 1
        };
    };

    set = (property: string, value: any) => {
        this.container[property] = value;
    };

}
