import { EntityInterface } from '../services/entity.service';
import { AccountCategoryYearBalance } from '../interfaces/entity-interfaces';

export const account_category_year_balance = {
  name: 'account_category_year_balance',
  params: {
    entityConfig: {
      name: 'account_category_year_balance',
      get: ['getLedgerTypesList'],
      post: ['profitCount', 'closeYearPeriod', 'deleteYearPeriodRecords']
    }
  }
};

export interface AccountCategoryYearBalanceEntityInterface extends EntityInterface<AccountCategoryYearBalance> {
  getLedgerTypesList: () => Promise<unknown>;
  profitCount: (data: { id: number }) => Promise<string[]>;
  closeYearPeriod: (data: { id: number }) => Promise<string>;
  deleteYearPeriodRecords: (data: { id: number }) => Promise<unknown>;
}
