import { Injectable } from '@angular/core';
import { InputDialogComponent } from '@proman/shared-dialogs/dialogs/input-dialog.component';
import { PurchaseEntityInterface, PurchaseTypes } from '@proman/resources/purchase';
import { Customer, Material, PromanFile, Supplier } from '@proman/interfaces/entity-interfaces';
import { copyToClipboard, mapOptionTranslate } from '@proman/utils';
import { SelectOption } from '@proman/interfaces/object-interfaces';
import { Entity } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import { Dialog } from './dialog.service';
import { PromanStateService } from './proman-state.service';
import { OcrEntityInterface } from '@proman/resources/ocr';
import { ToastService } from '@proman/services/toast.service';
import { getPublicSystemOptions } from '@proman/store/system-options';
import { Store } from '@ngrx/store';
import { RequestService } from '@proman/services/request.service';
import { TextPreviewDialogComponent } from '../components/text-preview-dialog.component';

@Injectable({ providedIn: 'root' })
export class OcrService {
  typeOptions: SelectOption[];
  purchaseEntityInterface: PurchaseEntityInterface;
  ocrEntityInterface: OcrEntityInterface;
  mediaUrl: string;

  constructor(
    private Entity: Entity,
    private Filter: FilterService,
    private PromanState: PromanStateService,
    private Dialog: Dialog,
    private Toast: ToastService,
    private Request: RequestService,
    private store: Store,
  ) {
    this.ocrEntityInterface = this.Entity.get('ocr');
    this.purchaseEntityInterface = this.Entity.get('purchase');
    this.purchaseEntityInterface.getPurchaseTypes().then((response: string[]) => this.typeOptions = response.map((item: string) => mapOptionTranslate(item, this.Filter.translate)));
    this.store.select(getPublicSystemOptions).subscribe((value: any) => this.mediaUrl = value.media_server + '/');
  }

  ocrAction = () => {
    this.Dialog.open(InputDialogComponent, {
      header: 'select_file',
      mainField: {
        name: 'file',
        key: 'file',
        type: 'files',
        config: { multiple: false }
      }
    }).then((result: { file: PromanFile[] }) => {
      if (!result || result.file?.length < 2) return;
      this.Request.post(this.mediaUrl + 'ocr', { id: result.file[1].newId }).then((response) => {
        if (!response.text) return;
        copyToClipboard(response.text);
        console.log(response.text);
        this.Dialog.open(TextPreviewDialogComponent, { disableCopy: true, disablePrint: true, text: response.text });
      });
    })
  }

  invoiceAnalyze = () => {
    return this.Dialog.open(InputDialogComponent, {
      header: 'select_purchase_type',
      mainField: {
        name: 'purchase_type',
        key: 'type',
        type: 'select',
        config: { options: this.typeOptions }
      },
      parameters: [
        {
          name: 'files',
          key: 'files',
          type: 'files',
          config: { multiple: false }
        }
      ]
    }).then((response: { type: PurchaseTypes, files: PromanFile[] }) => {
      if (response && response.type && response.files && response.files[1]) {
        (response as any).files = response.files[1].id;
        this.ocrEntityInterface.analyzeInvoice({
          id: response.files,
          type: response.type
        }).then((analysisResponse: {
          supplier: Supplier,
          customer: Customer,
          date: string,
          invoiceNumber: string,
          items: Material[],
          type: string,
          id: number,
        }) => {
          if (analysisResponse.type === 'purchase' && analysisResponse.id) {
            this.PromanState.to('Purchase', analysisResponse.id);
            return;
          }

          if (analysisResponse.type === 'invoice' && analysisResponse.id) {
            this.PromanState.to('InvoicesEdit', analysisResponse.id);
            return;
          }
        }).catch((e) => this.Toast.pop('error', e.error?.error));
      }
    });
  };
}
